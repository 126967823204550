import Hotjar from '@hotjar/browser';

import { envVariables } from '@app-constants/envVariables';

export function initHotJar() {
  const { integrationTestExecution } = envVariables;
  const { siteId = '', version = '' } = envVariables.hotjar;

  const castedSiteId = Number(siteId);
  const castedVersion = Number(version);

  if (integrationTestExecution || !castedSiteId || !castedVersion) return null;

  return Hotjar.init(castedSiteId, castedVersion);
}
