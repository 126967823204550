export const envVariables = {
  accountManagerURL: process.env.ACCOUNT_MANAGER_URL,
  rolesAndPermissionURL: process.env.ROLES_AND_PERMISSIONS_URL,
  serviceName: process.env.SERVICE_NAME,
  applicationVersion: process.env.APPLICATION_VERSION,
  bffGraphqlUrl: `${process.env.BFF_GRAPHQL_URL}`,
  bffEndpointUrl: `${process.env.BFF_ENDPOINT_URL}`,
  bffHostUrl: `${process.env.BFF_HOST_URL}`,
  cancelSubscriptionEnabled: process.env.CANCEL_SUBSCRIPTION_ENABLED,
  dataDog: {
    appId: process.env.DD_APPLICATION_ID,
    clientToken: process.env.DD_CLIENT_TOKEN,
    sampleRate: Number(process.env.DD_SAMPLE_RATE),
    replaySampleRate: Number(process.env.DD_REPLAY_SAMPLE_RATE),
    version: process.env.DD_VERSION,
  },
  domain: process.env.DOMAIN,
  expiresInDays: process.env.EXPIRES_IN_DAYS_BATCH,
  gpEnv: process.env.GP_ENV,
  keycloak: {
    realm: process.env.KEYCLOAK_REALM || '',
    url: process.env.KEYCLOAK_URL,
    clientId: process.env.KEYCLOAK_CLIENT_ID || '',
  },
  gtm: {
    container: process.env.GTM_CONTAINER,
    environmment: process.env.GTM_ENVIRONMENT,
  },
  userpilotToken: process.env.USERPILOT_TOKEN,
  nodeEnv: process.env.NODE_ENV,
  platformServicesToolURL: process.env.PLATFORM_SERVICES_TOOL_URL,
  paymentUrl: process.env.PAYMENT_URL,
  yggdrasilUrl: process.env.YGGDRASIL_URL,
  snowplowUrl: process.env.SNOWPLOW_URL,
  daysUntilEligibleSanitization: process.env.DAYS_UNTIL_ELIGIBLE_SANITIZATION,
  cognitoConfig: {
    aws_project_region: process.env.AWS_PROJECT_REGION,
    aws_cognito_identity_pool_id: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.AWS_OAUTH_DOMAIN,
    },
  },
  challenges: {
    metricId: {
      steps: process.env.CHALLENGE_METRIC_STEPS_ID,
      check_ins: process.env.CHALLENGE_METRIC_CHECK_INS_ID,
      points: process.env.CHALLENGE_METRIC_POINTS_ID,
    },
  },
  deeplinksWellhubDomain: process.env.DEEPLINKS_WELLHUB_DOMAIN,
  yearInReviewUrl: process.env.YEAR_IN_REVIEW_URL,
  authProvider: `${process.env.AUTH_PROVIDER}`,
  iosStoreUrl: process.env.IOS_STORE_URL,
  androidStoreUrl: process.env.ANDROID_STORE_URL,
  corporateApiEmail: process.env.CORPORATE_API_EMAIL,
  muiXLicense: process.env.MUI_X_LICENSE || '',
  removalConfirmationThreshold: Number(process.env.REMOVAL_CONFIRMATION_THRESHOLD),
  qrCodeExperimentId: process.env.QR_CODE_EXPERIMENT_ID || '',
  qrCodeExperimentPageName: process.env.QR_CODE_EXPERIMENT_PAGE_NAME || '',
  logoutRedirectUri: process.env.LOGOUT_REDIRECT_URI,
  billingHomepageUrl: process.env.BILLING_HOMEPAGE_URL,
  integrationTestExecution: process.env.INTEGRATION_TEST_EXECUTION,
  developersPortalSFTPDocURL: process.env.DEVELOPERS_PORTAL_SFTP_DOC_URL,
  developersPortalAPIDocURL: process.env.DEVELOPERS_PORTAL_API_DOC_URL,
  hotjar: {
    siteId: process.env.HOTJAR_SITE_ID,
    version: process.env.HOTJAR_VERSION,
  },
};
